<template>
  <!-- 资料库>目录管理 -->
  <div class="dict">
    <!-- 表单 -->
    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <el-form-item>
        <el-input v-model="form.keyword" placeholder="文件名称" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchKeyword()"
          >查询</el-button
        >
      </el-form-item>

      <el-form-item>
        <el-button @click="resetTabDate()">重置</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 1.04167vw">
      <el-button type="primary" size="medium" @click="selectDel"
        >批量删除</el-button
      >
      <el-button size="medium" @click="clearRecycle">清空回收站</el-button>
    </div>

    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="true"
      :showCheckBox="true"
      :checkBoxFixed="'left'"
      @selection-change="selectRowChange"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <el-table-column fixed="left" type="selection" width="55">
      </el-table-column>
      <el-table-column
        prop="fileName"
        label="文件名称"
        align="center"
        width="15.625vw"
        slot="fileName"
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        align="center"
        slot="createName"
        width="7.8125vw"
      >
      </el-table-column>
      <el-table-column
        prop="size"
        label="文件大小"
        align="center"
        slot="size"
        width="7.8125vw"
      >
        <template slot-scope="scope">
          {{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB
        </template>
      </el-table-column>
      <el-table-column
        prop="updateBy"
        label="删除人"
        width="150"
        align="center"
        slot="updateBy"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        slot="updateTime"
        width="13.02083vw"
        label="删除时间"
        align="center"
      ></el-table-column>
      <!-- type="expand" -->
      <el-table-column>
        <template slot-scope="scope">
          {{ scope.row.createName }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        slot="handle"
        align="center"
        width="13.02083vw"
        fixed="right"
      >
        <template slot-scope="scope">
          <div>
            <el-button size="mini" type="text" @click="delFile(scope.row.id)"
              ><span style="color: #909399">删除</span>
            </el-button>
            <el-button size="small"  type="text" @click="recovery(scope.row.id)"
              ><span style="color: #909399">恢复到原位</span>
            </el-button>
            <el-button
              size="small"
              type="text"  
              @click="recovery(scope.row.id, true)"
              ><span style="color: #909399">恢复到指定的位置</span>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </l-table>
    <el-dialog title="恢复到指定位置" :visible.sync="positionPanel" width="30%" >
      <span style="margin-right: 0.52083vw">活动区域</span>
      <el-select v-model="directoryId" placeholder="请选择目录">
        <el-option
          v-for="(item, index) in directoryList"
          :key="index"
          :label="item.directoryName"
          :value="item.id"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="positionPanel = false">取 消</el-button>
        <el-button type="primary" :disabled="uploadLoading" :loading="uploadLoading" @click="recoveryToDirectory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      tableLoading: true, // tableloading
      selectData: [],
      expandRow: [],
      directoryId: "",
      directoryList: [],
      positionPanel: false,
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "文件名称",
          slotName: "fileName",
        },
        {
          label: "文件大小",
          slotName: "size",
        },
        {
          label: "创建人",
          slotName: "createName",
        },

        {
          label: "更新时间",
          slotName: "updateTime",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "0",
      form: {
        keyword: "",
        options: [
          {
            label: "异常",
            value: "0",
          },
          {
            label: "正常",
            value: "1",
          },
        ],
      },
      tableData: [],
      permissionButtonList: [], // 权限按钮list
      uploadLoading:false,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
    this.getThirdDirectoryList();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      let params = Object.assign(
        {
          fileId: this.id,
        },
        {
          current: this.page.num,
          size: this.page.size,
          fileName: this.form.keyword,
        }
      );
      this.tableLoading = true;
      this.$api.getRecycleList(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          if (res.data.data.total >= 1) {
            this.pageFlag = true;
          } else {
            this.pageFlag = false;
          }
        } else {
          this.tableData = [];
          this.page.total = 0;
          this.pageFlag = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    resetTabDate(arr) {
      this.form.keyword = "";
      this.page.num = 1;
      this.init();
    },
    getThirdDirectoryList() {
      this.$api.getThirdDirectory().then((res) => {
        if (res.data.code == 0) {
          this.directoryList = res.data.data;
        }
      });
    },
    delFileRequest(params) {
      //发送删除文件请求
      this.$api.delRecycleFiles(params).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.init()
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    delFile(id) {
      //单个删除
      this.$confirm("删除后文件不能恢复,确认删除吗?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: 'addChild',
      })
        .then(() => {
          this.page.num = 1;
          this.delFileRequest({ type: "1", id: id + "" });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    recoveryToDirectory() {
      if (this.directoryId) {
        //发送请求
        this.recoveryFile({
          id: this.fileId,
          type: "2",
          directoryId: this.directoryId,
        });
      } else {
        this.$message.error("请选择目录");
      }
    },
    recoveryFile(params) {
      //文件恢复请求
      this.uploadLoading = true
      this.$api.recoveryFile(params).then((res) => {
        if (res.data.code == 0) {
          this.positionPanel = false;
          this.$message({
            type: "success",
            message: params.type == 1 ? "恢复成功" : "清空成功",
          });
          this.init()
        } else {
          this.$message.error(res.data.msg);
        }
        this.uploadLoading = false
      }).catch(error=>{
        this.uploadLoading = false
      });
    },
    recovery(id, pos) {
      if (pos) {
        this.positionPanel = true;
        this.fileId = id;
      } else {
        this.$confirm("是否确认恢复到原目录?", "恢复到原位", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: 'addChild',
        })
          .then(() => {
            this.recoveryFile({ id, type: "1", directoryId: "" });
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
      }
    },
    clearRecycle() {
      this.$confirm(
        "清空回收站将删除所有文件,且删除后文件不能恢复,确认清空吗?",
        "确认清空回收站",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: 'addChild',
        }
      )
        .then(() => {
          this.delFileRequest({ type: "2" });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    selectDel() {
      if (this.selectData.length) {
        this.$confirm("删除后文件将不能恢复,确定删除吗?", "确认批量删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass: 'addChild',
        })
          .then(() => {
            let ids = this.selectData.map((item) => item.id).join(",");
            this.page.num = 1;
            this.delFileRequest({ type: "1", id: ids });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    searchKeyword() {
      this.page.num = 1;
      this.init();
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.keyword = "";
      this.page.num = 1;
      this.init();
    },
    /** 分页 */
    handleCurrentChange(val) {
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
    /**多选 */
    selectRowChange(arr) {
      this.selectData = arr;
    },
    handleSelect(selection, row) {
      this.selectData = selection;
    },
    handleSelectAll(arr) {
      this.selectData = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
}
</style>
<style>
.addChild .el-button{
  width: 96px;
  /* height: 38px; */
}
</style>
